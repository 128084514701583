import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default function Navbar(props) {
  return (
    <header className='mb-4'>
    <nav className={`${props.mode.bg} border-gray-200 px-4 lg:px-6 py-2.5`}>
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <a href="/" className="flex items-center">
                <img src={props.logo} className="mr-3 h-6 sm:h-9" alt="Sensod Logo" width="124.58" height="38" />
            </a>
            <div className="flex items-center lg:order-2">
                <label className="inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" onClick={props.toggleMode} />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className={`ms-3 text-sm font-medium ${props.mode.text}`}>{props.ButtonText}</span>
                    </label>
            </div>
            <div className="justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                    <li>
                        <Link className={`block py-2 pr-4 pl-3 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 ${props.mode.text}`} to="/">Home</Link></li>
                    <li>
                        <Link className={`block py-2 pr-4 pl-3 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 ${props.mode.text} opacity-70 hover:opacity-100`} to="/about">About</Link></li>
                </ul>
            </div>
        </div>
    </nav>
</header>
  )
}

Navbar.propTypes = {logo: PropTypes.string.isRequired}
import React, {useState} from 'react'
import PropTypes from 'prop-types'


export default function TextForm(props) {
    const [text, setText] = useState('Enter Your Text here');

    const handleUpClick = ()=> {
        let newText = text.toUpperCase();
        setText(newText);
    }

    const speak = () => {
      let msg = new SpeechSynthesisUtterance();
      msg.text = text;
      window.speechSynthesis.speak(msg);
    }

    const handleOnChange = (event)=> {
        setText(event.target.value);
    }

    const handleLowClick = ()=> {
        let lowerNewText = text.toLowerCase();
        setText(lowerNewText);
    }

    const handleClear = ()=> {
      let lowerNewText = '';
      setText(lowerNewText);
  }

  return (
    <div className={`container-fluid ${props.mode.text}`} style={{color: props.mode.text ===`text-white`?`#fff`:`#000`}}>
      <div className='mx-auto max-w-screen-xl mb-5'>
      <h1 className={`mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl text-center`}>{props.heading}</h1>
        <div className='form_input flex flex-col mb-3'>
          <label htmlFor="message" className={`block mb-2 text-sm font-medium`}>Your message</label>
          <textarea id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={text} onChange={handleOnChange}></textarea>
        </div>
        <div className='flex flex-row flex-wrap gap-3'>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleUpClick}>Convert Uppercase Text</button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleLowClick}>Convert Lowercase Text</button>
        <button type="submit" onClick={speak} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Speak</button>
        <button type="submit" onClick={handleClear} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Clear</button>
        </div>
      </div>
      <div className="mx-auto max-w-screen-xl">
        <h2 className='mb-4 text-3xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-4xl'>Your Text Summer</h2>
        <p>{text.trim().length>0?text.split(" ").length: 0} words and {text.trim().length} character</p>
        <p>{0.008 * text.split(" ").length} Minutes read</p>
        <h2>Preview</h2>
        {text.length > 0 ?text: "Enter something in the text box above to preview here"}
      </div>
    </div>
  )
}

TextForm.propTypes = {heading: PropTypes.string}

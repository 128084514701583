import './index.css';
import logo from './logo.svg';

import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import { useState } from 'react';
import Alert from './components/Alert';

function App() {
  const [themeMode, setThemeMode] = useState({
    bg: 'bg-white',
    text: 'text-black'
  });

  const [themeModeText, setThemeModeText] = useState('Dark Mode');

  const [alert, setAlert] = useState(null);

  const showAlert = (message, type)=> {
    setAlert({
      msg: message,
      type: type
    })

    setTimeout(() => {
      setAlert(null)
    }, 2000);
  }

  const toggleMode = ()=> {
    if(themeMode.bg === 'bg-white') {
      setThemeMode({
        bg: 'bg-neutral-800',
        text: 'text-white'
      });
      document.body.classList.add('bg-black');
      document.body.classList.remove('bg-white');
      setThemeModeText('Light Mode');

      showAlert("Dark Mode On", "Success");
    }else {
      setThemeMode({
        bg: 'bg-white',
        text: 'text-black'
      });
      document.body.classList.remove('bg-black');
      document.body.classList.add('bg-white');
      setThemeModeText('Dark Mode');
      showAlert("Light Mode On", "Success");
    }
  }
  return (
    <>
      <Navbar logo={logo} mode={themeMode} toggleMode={toggleMode} ButtonText={themeModeText} />

      <Alert alert={alert} />

      <TextForm heading="Textarea Calculator" mode={themeMode} />
    </>
  );
}

export default App;

import React from 'react'
import logo from './logo.svg';
import Navbar from './components/Navbar'
import { useState } from 'react';


export default function About() {
  const [themeMode, setThemeMode] = useState({
    bg: 'bg-white',
    text: 'text-black'
  });

  const [themeModeText, setThemeModeText] = useState('Dark Mode');

  const toggleMode = ()=> {
    if(themeMode.bg === 'bg-white') {
      setThemeMode({
        bg: 'bg-neutral-800',
        text: 'text-white'
      });
      document.body.classList.add('bg-black');
      document.body.classList.remove('bg-white');
      setThemeModeText('Light Mode');

    }else {
      setThemeMode({
        bg: 'bg-white',
        text: 'text-black'
      });
      document.body.classList.remove('bg-black');
      document.body.classList.add('bg-white');
      setThemeModeText('Dark Mode');
    }
  }
  return (
    <>
    <Navbar logo={logo} mode={themeMode} toggleMode={toggleMode} ButtonText={themeModeText} />
    <div className={`container-fluid`}>
      <div className='mx-auto max-w-screen-xl mb-5'>
      <h1 className={`mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl text-center`}>About Us</h1>
      </div>
    </div>
    </>
  )
}